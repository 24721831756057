import React, { createContext, Dispatch, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { authAPI } from '../api/authAPI';
import { userAPI } from '../api/userAPI';

import { TokensData } from '../libs/types';
import AuthStorage from '../libs/AuthStorage';
import { UserDataFull } from '../libs/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const dateDiffInSeconds = (date1: Date, date2: Date): number => {
  return Math.abs((date2.getTime() - date1.getTime()) / 1000);
};

interface Props {
  children: JSX.Element[] | JSX.Element;
}

type AuthContextType = {
  currentUser?: UserDataFull | null;
  setCurrentUser?: Dispatch<any>;
  updateUser: (data: any) => void;
  lastUpdate: () => Date | null;
  isLoggedIn: () => boolean;
  // setUser: (user: object) => void;
  login: (data: any) => Promise<UserDataFull | false>;
  register: (data: any) => Promise<UserDataFull>;
  logout: () => Promise<void>;
  setupUser: () => Promise<UserDataFull>;
  storeTokens: (tokens: TokensData) => void;
  getTokens: () => TokensData;
  refreshTokens: () => Promise<TokensData>;
  handleChangeLang: (lang: string) => void; // TODO: move to separated file
  isRefreshing: boolean;
};

// @ts-ignore
export const AuthContext = createContext<AuthContextType>();

export const AuthContextProvider = ({ children }: Props) => {
  const { i18n } = useTranslation();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeLang = async (lang: string) => {
    await i18n.changeLanguage(lang);
  };

  const [currentUser, setCurrentUser] = useState(AuthStorage.getUser());

  useEffect(() => {
    if (currentUser && currentUser.lang) {
      handleChangeLang(currentUser.lang.toLowerCase());
    }
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.is_banned && location.pathname !== '/blocked') {
      navigate('/blocked', {
        state: {
          user: currentUser,
        },
      });
    }
  }, [currentUser?.is_banned, location.pathname]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      Sentry.setUser({
        id: currentUser.id,
        email: currentUser.email,
        username: currentUser.name,
        ip_address: '{{auto}}',
        auth_provider: currentUser.auth_provider,
        public_id: currentUser.public_id,
      });
      return;
    }
    Sentry.setUser(null);
  }, [currentUser]);

  const updateUser = (data: UserDataFull): void => {
    const newData = { ...currentUser, ...data };
    AuthStorage.storeUser(newData);
    setCurrentUser(newData);
  };

  const lastUpdate = (): Date | null => {
    return AuthStorage.lastUpdate();
  };

  const isLoggedIn = (): boolean => {
    return currentUser?.id !== undefined;
  };

  const setupUser = async (): Promise<UserDataFull> => {
    const user = await userAPI.me();
    AuthStorage.storeUser(user);
    setCurrentUser(user);

    return user;
  };

  const login = async (data: {
    email: string;
    password: string;
  }): Promise<UserDataFull | false> => {
    const tokens = await authAPI.login(data);
    AuthStorage.storeTokens(tokens);

    const user = await setupUser();
    await i18n.changeLanguage(user.lang.toLowerCase());
    localStorage.setItem('lang', user.lang.toLowerCase());
    console.log('login', user);

    if (user.is_banned) {
      navigate('/blocked', {
        state: {
          user: user,
        },
      });

      return false;
    }
    return user;
  };

  const register = async (data: {
    email: string;
    password: string;
    language_code: string;
  }): Promise<UserDataFull> => {
    const tokens = await authAPI.register(data);
    AuthStorage.storeTokens(tokens);

    return await setupUser();
  };

  const logout = async (): Promise<void> => {
    try {
      const token = AuthStorage.getValidRefreshToken();
      if (token) {
        await authAPI.logout(token);
      }
      AuthStorage.forgetUser();
      AuthStorage.forgetTokens();
      setCurrentUser(null);
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const refreshTokens = async (): Promise<TokensData> => {
    const token = AuthStorage.getValidRefreshToken();
    if (!token) {
      throw new Error('No valid refresh token');
    }
    const tokens = await authAPI.refresh(token);
    AuthStorage.storeTokens(tokens);

    return tokens;
  };

  const storeTokens = (tokens: TokensData): void => {
    AuthStorage.storeTokens(tokens);
  };

  const getTokens = (): TokensData => AuthStorage.getTokens();

  // useEffect(() => {
  //   if (currentUser) {
  //     AuthStorage.storeUser(currentUser);
  //     return;
  //   }
  //   AuthStorage.forgetUser();
  // }, [currentUser]);

  useEffect(() => {
    const telegram = window.Telegram?.WebApp;
    if (!telegram?.initData || isLoggedIn()) {
      return;
    }
    telegram.ready();
    authAPI
      .authViaTelegram(telegram, (i18n.language || 'en').toUpperCase())
      .then((tokens) => {
        AuthStorage.storeTokens(tokens);

        return setupUser();
      })
      .then(() => {
        navigate('/');
      })
      .catch((error: any) => {
        console.error(error);
        navigate('/login');
      });
  }, []);

  useEffect(() => {
    const lastUpdateUser = lastUpdate();
    const currentDate = new Date();
    const expires = AuthStorage.getAccessTokenExpires();
    // console.log(lastUpdateUser, currentDate, Math.abs(currentDate.getTime() - lastUpdateUser!.getTime())/1000);

    if (
      isLoggedIn() &&
      (!lastUpdateUser ||
        !expires ||
        dateDiffInSeconds(currentDate, lastUpdateUser) > 60 ||
        dateDiffInSeconds(currentDate, lastUpdateUser) < 120)
    ) {
      try {
        setupUser();
      } catch (error: any) {
        console.error(error);
        navigate('/login');
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        updateUser,
        lastUpdate,
        isLoggedIn,
        login,
        register,
        logout,
        setupUser,
        getTokens,
        storeTokens,
        refreshTokens,
        handleChangeLang,
        isRefreshing,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }
  return context;
};
